import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("g", { "clip-path": "url(#mail)" }, [
      _createElementVNode("path", {
        d: "M2.5 2.5h15a.83.83 0 0 1 .83.83v13.34a.83.83 0 0 1-.83.83h-15a.83.83 0 0 1-.83-.83V3.33a.83.83 0 0 1 .83-.83Zm14.17 3.53-6.61 5.92L3.33 6v9.82h13.34v-9.8ZM3.76 4.17l6.3 5.55 6.2-5.55H3.75Z",
        fill: "#F2F2F2"
      })
    ]),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "mail" }, [
        _createElementVNode("path", {
          fill: "#fff",
          d: "M0 0h20v20H0z"
        })
      ])
    ])
  ]))
}